import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/css/home.css';
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';
import Insta from './insta';
import ExploreCollection from './ExploreCollection';
import Virtualappointment from './VirtualAppointment';
import Customer from './Customer';
import ShopDiamondShape from './ShopDiamondShape';
import Gemstones from './GemstoneShop';
import RingStyle from './RingStyle';
import Ringbuilderhome from './Ringbuilderhome';
import SignupTo from './SignupTo';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux';
import { client_name } from '../../Helpers/request';
import ParallaxBanner from './ParallaxBanner';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Dia from '../../assets/images/dia.png';
import StoreMap from '../Map/StoreMap';

const Home = () => {
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);
  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 3000);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content={client_name.c_name} />
      </Helmet>
      <StoreMap />
      {/* <BannerSlider /> */}
      {/* <ParallaxBanner /> */}
      {/* <RingStyle /> */}
      {/* <Ringbuilderhome /> */}
      <ShopDiamondShape />
      {/* <ExploreCollection /> */}
      {/* <Gemstones /> */}
      {/* <Virtualappointment /> */}
      {/* <Customer /> */}
      {/* <Insta /> */}
      {/* <SignupTo /> */}

      {/* <Modal
        className="bubble-diamond-popup"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <img src={Dia} alt="" />
        <Modal.Body>
          Buy One Carat Diamond
          <br /> for <span>$499</span> <br />
          and a Setting for Free
        </Modal.Body>
        <Modal.Footer>
          <Link variant="primary" to="/diamonds">
            Get Now
          </Link>
          <Button variant="secondary" onClick={() => setShow(false)}>
            No Thanks
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <AboutSection/> */}
      {/* <Colordiamond /> */}
      {/* <Letestcreation/> */}
      {/* <ServiceSection /> */}
      {/* <SignupTo /> */}
      {/* <Newsletter /> */}
      {/* <Featured /> */}
      {/*Collection1 :- Wedding band,Diamonds Earrings,Fine Jewelry */}
      {/* <Collection1 /> */}
      {/* <StateJewelry /> */}
      {/*Collection2 :- Pendants,Bracelets,Gemstones */}
      {/* <Collection2 /> */}
      {/* <IndianJewelry /> */}
      {/* <Philanthropy /> */}
      {/* <Testimonial /> */}
      {/* <Insta /> */}
      {/* <Newsletter /> */}
    </>
  );
};

export default Home;
