import React, { useState } from 'react';
import { VectorMap } from '@react-jvectormap/core';
// import { worldMill } from "@react-jvectormap/world";
import { Container } from 'react-bootstrap';
import '../../assets/css/store.css';
import africaMill from '../../../node_modules/@react-jvectormap/africa/dist/africaMerc.json';
import { map } from 'jquery';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom';
function StoreMap() {
  const history = useHistory();
  const mapRef = useRef();
  const data = {
    AO: 100, // Angola
    BW: 100, // Botswana
    CF: 100, // Central African Republic
    CD: 100, //Congo (the "Democratic" Republic of the)
    CI: 100, // Ivory cost
    LR: 100, // Liberia
    NA: 100, // Namibia
    CG: 100, // The Republic of Congo OR "Congo (the)"
    SL: 100, //Sierra Leone
    ZA: 100, //South Africa
    TZ: 100, // Tanzania
    LS: 100, //Lesotho
    ZW: 100, //Zimbabwe
    GN: 100, //Guinea

    DZ: 100, //Algiria
    SD: 100, //Sudan
    NG: 100, //The Federal Republic of Nigeria
  };
  const colorScale = ['#606C5D'];
  // const colorScale = ['#082262']
  const [conteryName, setCountryName] = useState(null);
  useEffect(() => {
    if (africaMill?.content?.paths) {
      let ary = Object.keys(data).map((k) => {
        return africaMill?.content?.paths[k].name;
      });
      if (ary?.length) {
        setCountryName(ary);
      }
    }
  }, [africaMill]);

  const [countryUrl, setCountryUrl] = useState();
  // console.log(countryUrl)

  return (
    <div className="mapWrapper">
      <Container className="bw_custome_container">
        <div className="bw_google_review_title d-flex justify-content-center">
          <h2 className="title_client">
            Legal solutions with an African heartbeat
          </h2>
        </div>
        <div className="innerMapWrp">
          <VectorMap
            ref={mapRef}
            borderColor="black"
            zoomOnScroll={false}
            map={africaMill}
            style={{
              width: '100%',
              height: '100%',
              paddingTop: '40px',
              backgroundColor: '#e0e2e7',
            }}
            backgroundColor="#e0e2e7"
            onRegionClick={(event, code) => {
              setCountryUrl(event);
              history.push('/diamonds');
            }}
            regionStyle={{
              initial: {
                'fill-opacity': 1,
                stroke: '#ddd',
                'stroke-width': 3,
                'stroke-opacity': 1,
              },
              hover: {
                'fill-opacity': 0.8,
                cursor: 'pointer',
              },
              selected: {
                fill: '#2938bc', // onclick colour of state
              },
            }}
            series={{
              regions: [
                {
                  scale: colorScale,
                  values: data,
                  min: 0,
                  max: 100,
                },
              ],
            }}
          />
          <ul>
            <li>
              <h2>Stones from Origin</h2>
            </li>
            {conteryName?.length
              ? conteryName.map((i) => (
                  <li>
                    <Link to="/diamonds">{i}</Link>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </Container>
    </div>
  );
}

export default StoreMap;
