import React from 'react';
import Banner from './Components/banner';
import EduBanner from '../../assets/images/education-web/education.jpg';
import { Container } from 'react-bootstrap';
import EducationBox from './Components/educationBox';
import Gemstone from '../../assets/images/education-web/gemstone.png';
import WeddingRing from '../../assets/images/education-web/wedding.png';
import EngagementRing from '../../assets/images/education-web/engagement.png';
import Fine from '../../assets/images/education-web/fine.png';
import LooseDia from '../../assets/images/education-web/loose.png';

const Education = () => {
  return (
    <>
      <Banner bannerImg={EduBanner} title={'Education'} mainEducation />

      <div className="education-boxes">
        <EducationBox
          title={'Gemstone'}
          image={Gemstone}
          url={'/education/gemstone-education'}
        />
        <EducationBox
          title={'Loose Diamonds'}
          image={LooseDia}
          url={'/education/loose-diamonds-education'}
        />
        <EducationBox
          title={'Wedding Rings'}
          image={WeddingRing}
          url={'/education/wedding-ring-education'}
        />
        <EducationBox
          title={'Engagement Rings'}
          image={EngagementRing}
          url={'/education/engagement-ring-education'}
        />
        <EducationBox
          title={'Fine Jewelry'}
          image={Fine}
          url={'/education/fine-jewelry-education'}
        />
      </div>

      {/* <Container>
          <Help/>
        </Container> */}
    </>
  );
};

export default Education;
