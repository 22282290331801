import React from 'react';
import { Link } from 'react-router-dom';
import Arrow from '../../../assets/images/education-web/arrow-right.svg';

const EducationBox = ({ title, image, url }) => {
  return (
    <div className="education-box">
      <img src={image} alt="" />

      <div className="content">
        <Link to={url}>
          <h2>{title}</h2>
        </Link>
        <Link to={url} className="d-block viewmore">
          View More
        </Link>
        <Link to={url}>
          <img src={Arrow} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default EducationBox;
