import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import round from '../../assets/images/home/solitaire1.png';
import RoundShape from '../../assets/images/style/solitaire.png';
import princess from '../../assets/images/style/vintage.png';
import cushion from '../../assets/images/style/channel.png';
import emerald from '../../assets/images/style/eternity.png';
import oval from '../../assets/images/style/curved.png';
import radiant from '../../assets/images/style/aniversary.png';
import asscher from '../../assets/images/style/halo.png';
import marquise from '../../assets/images/style/stackable.png';
import heart from '../../assets/images/style/sidestone.png';
import pear from '../../assets/images/style/pave.png';

import roundImg from '../../assets/images/style/solitaire.png';
import princessImg from '../../assets/images/style/vintage.png';
import cushionImg from '../../assets/images/style/channel.png';
import emeraldImg from '../../assets/images/style/eternity.png';
import ovalImg from '../../assets/images/style/curved.png';
import radiantImg from '../../assets/images/style/aniversary.png';
import asscherImg from '../../assets/images/style/halo.png';
import marquiseImg from '../../assets/images/style/stackable.png';
import heartImg from '../../assets/images/style/sidestone.png';
import pearImg from '../../assets/images/style/pave.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom';

import Angola from '../../assets/images/flag/Angola.gif';
import Botswana from '../../assets/images/flag/Botswana.gif';
import Central_African_Rep from '../../assets/images/flag/Central_African_Rep.gif';
import Dem from '../../assets/images/flag/DRC.jpg';
import Côte from '../../assets/images/flag/Cote_d_Ivoire.gif';
import Liberia from '../../assets/images/flag/Liberia.gif';
import Namibia from '../../assets/images/flag/Namibia.gif';
import Congo from '../../assets/images/flag/Rep_of_the_Congo.gif';
import Sierra from '../../assets/images/flag/Sierra_Leone.gif';
import South from '../../assets/images/flag/South_Africa.gif';
import Tanzania from '../../assets/images/flag/Tanzania.gif';
import Lesotho from '../../assets/images/flag/Lesotho.gif';
import Zimbabwe from '../../assets/images/flag/Zimbabwe.gif';
import Guinea from '../../assets/images/flag/Guinea.gif';

function ShopDiamondShape() {
  var history = useHistory();
  var settings2 = {
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const shopbyshape = (value) => {
    var data1 = {
      shape: value,
    };
    sessionStorage.setItem('bw_d_filter', JSON.stringify(data1));
    history.push('/diamonds');
    window.scrollTo(0, 0);
  };

  const contries = [
    {
      name: 'Angola',
      flag: Angola,
      link: 'angola',
    },
    {
      name: 'Botswana',
      flag: Botswana,
      link: 'botswana',
    },
    {
      name: 'Central African Rep.',
      flag: Central_African_Rep,
      link: 'central-african-rep',
    },
    {
      name: 'Dem. Rep. Congo',
      flag: Dem,
      link: 'dem-rep-congo',
    },
    {
      name: "Côte d'Ivoire",
      flag: Côte,
      link: "côte-d'ivoire",
    },
    {
      name: 'Liberia',
      flag: Liberia,
      link: 'liberia',
    },
    {
      name: 'Namibia',
      flag: Namibia,
      link: 'namibia',
    },
    {
      name: 'Congo',
      flag: Congo,
      link: '',
    },
    {
      name: 'Sierra Leone',
      flag: Sierra,
      link: 'sierra-leone',
    },
    {
      name: 'South Africa',
      flag: South,
      link: 'south-africa',
    },
    {
      name: 'Tanzania',
      flag: Tanzania,
      link: '',
    },
    {
      name: 'Lesotho',
      flag: Lesotho,
      link: 'lesotho',
    },
    {
      name: 'Zimbabwe',
      flag: Zimbabwe,
      link: 'zimbabwe',
    },
    {
      name: 'Guinea',
      flag: Guinea,
      link: 'guinea',
    },
  ];

  return (
    <>
      <section className="rcs_ring_style_section">
        <Container className="rcs_custom_home_container">
          <Row>
            <Col md={12}>
              <div className="rcs_shop_by_diamond_title">
                <h2 className="title_one">
                  A Journey of Transformation and Responsibility
                </h2>
              </div>
            </Col>

            <Col lg={12} className="p-0">
              <div className="rcs_customer_review_slider">
                <Slider
                  className="rcs_customer_say_inner rcs_shop_diamond_slider"
                  {...settings2}
                >
                  {contries.map((contrie) => (
                    <div className="rcs_shop_by_diamond_box_main">
                      <div className="rcs_shop_by_diamond_box_img">
                        <Link to={contrie.link}>
                          <div className="rcs_shop_by_diamond_box_img_size">
                            <Image
                              className="rcs_shop_by_diamond_box_img_static"
                              src={contrie.flag}
                              alt="Round"
                            />
                            <Image
                              className="rcs_shop_by_diamond_box_img_hover"
                              src={contrie.flag}
                              alt="Round"
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="rcs_shop_by_diamond_box_text mt-5">
                        <Link to={contrie.link}>
                          <h3 className="m-0 text-dark">{contrie.name}</h3>
                        </Link>
                      </div>
                    </div>
                  ))}
                  {/* <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/ringsettings/style=Solitaire">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={RoundShape} alt="Round" />
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={roundImg} alt="Round" />
                                                </div>
                                            </Link>

                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/ringsettings/style=Solitaire">
                                                <h3 className="m-0 text-dark">Solitaire</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/ringsettings/style=Solitaire">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={princess} alt="Princess" />
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={princessImg} alt="Princess" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="ringsettings/style=Vintage">
                                                <h3 className="m-0 text-dark">Vintage</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/jewelry/wedding-rings/style=Channel%20Set">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={cushion} alt="Cushion" />
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={cushionImg} alt="Cushion" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/jewelry/wedding-rings/style=Channel%20Set">
                                                <h3 className="m-0 text-dark">Channel-set</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/jewelry/wedding-rings/style=Eternity">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={emerald} alt="Emerald" />
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={emeraldImg} alt="Emerald" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/jewelry/wedding-rings/style=Eternity">
                                                <h3 className="m-0 text-dark">Eternity</h3>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/jewelry/wedding-rings/style=Curved">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={oval} alt="Oval" />
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={ovalImg} alt="Oval" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/jewelry/wedding-rings/style=Curved">
                                                <h3 className="m-0 text-dark">Curved</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/jewelry/wedding-rings/style=Anniversary">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={radiant} alt="Radiant" />
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={radiantImg} alt="Radiant" />
                                                </div>

                                            </Link>

                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/jewelry/wedding-rings/style=Anniversary">
                                                <h3 className="m-0 text-dark">Aniversary</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div clasName="rcs_shop_by_diamond_box_img">
                                            <Link to="/ringsettings/style=Halo">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={asscher} alt="Asscher" />
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={asscherImg} alt="Asscher" />
                                                </div>

                                            </Link>

                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/ringsettings/style=Halo">
                                                <h3 className="m-0 text-dark">Halo</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/jewelry/wedding-rings/style=Stackable">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={marquise} alt="Marquise" />
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={marquiseImg} alt="Marquise" />
                                                </div>

                                            </Link>

                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/jewelry/wedding-rings/style=Stackable">
                                                <h3 className="m-0 text-dark">Stackable</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link>
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={heart} alt="Heart" />
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={heartImg} alt="Heart" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="ringsettings/style=Solitaire">
                                                <h3 className="m-0 text-dark">Sidestone</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/ringsettings/style=Pave">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={pear} alt="Heart" />
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={pearImg} alt="Heart" />
                                                </div>

                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/ringsettings/style=Pave">
                                                <h3 className="m-0 text-dark">Pave</h3>
                                            </Link>
                                        </div>
                                    </div> */}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ShopDiamondShape;
